aside {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 4;
}

.component-selector {
    display: flex;
    flex-direction: row;
}

.component-selector > .node-component {
    margin: 20px;
}

.node-component {
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: default;
}

.drag-handle {
    cursor: grab;
}

.control {
    display: flex;
    flex-direction: column;
    align-items: left;
}